<template>
  <div class="container-fluid supplier-content" id="supplier">
    <breadcrumb route="perfil-seguridad"></breadcrumb>
    <div class="skinny row">
      <div class="col-md-12">
        <h1>
          Fortalece tu
          <span>contraseña</span>.
        </h1>
      </div>
    </div>

    <div class="skinny row profile-form">
      <div v-if="!change_password">
        <div v-if="!verifying">
          <div>
            <div class="skinny row">
              <div class="col-md-12">
                <div class="alt-1"></div>
                <label>Escribe tu contraseña actual para continuar:</label>
              </div>
            </div>
            <div class="skinny row">
              <div class="col-md-4">
                <label>Contraseña Actual</label>
                <input type="password" class="form-control not_bg" v-model="password" />
              </div>
            </div>
            <div class="skinny row">
              <div class="col-md-12 space-top">
                <button class="btn btn-ins btn-md cnf-btn" @click="checkPassword">Continuar</button>
              </div>
            </div>
            <div class="skinny row" v-if="error_message != ''">
              <div class="col-md-12 space-top error_text">{{error_message}}</div>
            </div>
          </div>
          <div class="alt-1"></div>
        </div>
        <div class="skinny row" v-if="verifying">
          <div class="col-md-6">
            <div class="loading-sunflower"></div>
          </div>
        </div>
      </div>
      <div v-if="change_password">
        <div v-if="!changed && !changing">
          <div class="skinny row" style="display:block !important">
            <div class="alt-1"></div>
            <div class="col-md-4 form-group">
              <label style="width:100%">
                <strong>Nueva Contraseña</strong>
                <a type="button" @click="view" style="float:right">
                  <span class="glyphicon" v-if="!view_pass">&#xe105;</span>
                  <span class="glyphicon" v-else>&#xe106;</span>
                </a>
              </label>
              <input id="pwd-master" type="password" class="form-control" v-model="pw1" />
            </div>
            <div class="col-md-4 form-group">
              <label>
                <strong>Confirmar Contraseña</strong>
              </label>
              <input type="password" class="form-control" v-model="pw2" onpaste="return false;" />
            </div>
          </div>
          <div class="skinny row">
            <div class="col-md-8">
              <div class="skinny row">
                <div class="col-xs-6 nopad">
                  <label class="password-criteria ok" v-if="criteria[0]">
                    Una letra en minúscula
                    <span class="password-criteria-check"></span>
                  </label>
                  <label class="password-criteria not-check" v-else>
                    Una letra en minúscula
                    <span class="password-criteria-check"></span>
                  </label>
                </div>
                <div class="col-xs-6 nopad">
                  <label class="password-criteria ok" v-if="criteria[3]">
                    Un símbolo
                    <span class="password-criteria-check"></span>
                  </label>
                  <label class="password-criteria not-check" v-else>
                    Un símbolo
                    <span class="password-criteria-check"></span>
                  </label>
                </div>
              </div>
              <div class="skinny row">
                <div class="col-xs-6 nopad">
                  <label class="password-criteria ok" v-if="criteria[1]">
                    Una letra en mayúscula
                    <span class="password-criteria-check"></span>
                  </label>
                  <label class="password-criteria not-check" v-else>
                    Una letra en mayúscula
                    <span class="password-criteria-check"></span>
                  </label>
                </div>
                <div class="col-xs-6 nopad">
                  <label class="password-criteria ok" v-if="criteria[4]">
                    8 caracteres mínimo
                    <span class="password-criteria-check"></span>
                  </label>
                  <label class="password-criteria not-check" v-else>
                    8 caracteres mínimo
                    <span class="password-criteria-check"></span>
                  </label>
                </div>
              </div>
              <div class="skinny row">
                <div class="col-xs-6 nopad">
                  <label class="password-criteria ok" v-if="criteria[2]">
                    Un número
                    <span class="password-criteria-check"></span>
                  </label>
                  <label class="password-criteria not-check" v-else>
                    Un número
                    <span class="password-criteria-check"></span>
                  </label>
                </div>
                <div class="skinny row">
                  <div class="col-xs-6 nopad">
                    <label class="password-criteria ok" v-if="criteria[5]">
                      Sin letras o números consecutivos
                      <span class="password-criteria-check"></span>
                    </label>
                    <label class="password-criteria not-check" v-else>
                      Sin letras o números consecutivos
                      <span class="password-criteria-check"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="skinny row" v-if="pw1 != ''&& !criteria[6]">
            <div class="col-md-12 space-top">
              <p class="error_text">No puedes usar el nombre de RedGirasol en tu contraseña.</p>
            </div>
          </div>
          <div class="skinny row" v-if="pw2 != ''&& pw1 != pw2">
            <div class="col-md-12 space-top">
              <p class="error_text">Ambas contraseñas deben coincidir.</p>
            </div>
          </div>
          <div
            class="skinny row"
            v-if="criteria[0] && criteria[1] && criteria[2] && criteria[3] && criteria[4] && criteria[5] && criteria[6] && pw1 == pw2"
          >
            <div class="col-md-12">
              <button
                class="btn btn-ins btn-md"
                style="margin-top:10px"
                @click="confirmPassword"
              >Actualizar</button>
            </div>
          </div>
          <div class="skinny row" v-if="password_confirm_error != ''">
            <div class="col-md-12 space-top error_text">{{password_confirm_error}}</div>
          </div>
        </div>
        <div v-if="changed && !changing">
          <div class="skinny row">
            <div class="col-md-6">
              <h2>
                Tu
                <span>contraseña</span> se ha actualizado exitosamente.
              </h2>
            </div>
          </div>
          <div class="skinny row">
            <div class="col-md-6">
              <a class="btn btn-ins btn-md" href="/perfil/editar">Volver a Mi Perfil</a>
            </div>
          </div>
        </div>
        <div class="skinny row" v-if="changing">
          <div class="col-md-6">
            <div class="loading-sunflower"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
  export default  {
    props: ['user_id'],
    mounted() {
    },
    data() {
      return {
        change_password: true,
        show_all: true,
        pw1: '',
        pw2:'',
        code:'',
        changed:false,
        changing: false,
        verifying:false,
        password: '',
        criteria: [false, false, false, false, false, false, false],
        error_message: '',
        password_confirm_error: '',
        view_pass:false,
      }
    },
    watch: {
      pw1: function(){
        this.testPassword(this.pw1)
      },
    },
    methods: {
      checkPassword(){
        this.error_message = ''
        this.verifying = true
        axios.post('/api/perfil/password/verify', {
          password: this.password,
          code:this.code
        }).then(response => {
          this.verifying = false
          if(response.data){
            this.change_password = true

          }else{
            this.error_message = 'Contraseña incorrecta'
          }
        }).catch(error => {
          this.verifying = false
          if (typeof error.response.data.message !== 'undefined') {
              this.error_message = error.response.data.message
          }else{
            this.error_message = 'Ha ocurrido un error inténtalo de nuevo.'
          }

        })

      },
      confirmPassword(){
        this.password_confirm_error = ''
        this.changing = true
        let payload = {password: this.pw1}
        this.injectAccountMetadataToPayload(payload)
        axios.post('/api/perfil/password/change', payload).then(response => {
          this.changing = false
          this.changed = true
        }).catch(error => {
          this.changing = false
          if (typeof error.response.data.message !== 'undefined') {
              this.password_confirm_error = error.response.data.message
          }else{
            this.password_confirm_error = 'Ha ocurrido un error inténtalo de nuevo.'
          }

        })
      },
      view(){
        var x = document.getElementById("pwd-master");
         if (x.type === "password") {
           x.type = "text";
           this.view_pass = true;
         } else {
           x.type = "password";
           this.view_pass = false;
         }
      },
      testPassword(pass){
        //let pass = this.pw1
        var number = /.*[0-9].*/
        var lower = /.*[a-z].*/
        var upper = /.*[A-Z].*/
        var special = /.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~].*/
        var consecutive = ['abcd', 'bcde', 'cdef', 'defg', 'efgh', 'fghi', 'ghij', 'hijk', 'ijkl', 'jklm', 'klmn', 'lmnñ', 'mnño', 'nñop', 'ñopq', 'opqr', 'pqrs', 'qrst', 'rstu', 'stuv', 'tuvw', 'uvwx', 'vwxy', 'wxyz',
          '0123', '1234', '2345', '3456', '4567', '5678', '6789', '7890', '8901', '9012', '2109', '1098', '0987', '9876', '8765', '7654', '6543', '5432', '4321', '3210',
          'aaaa', 'bbbb', 'cccc', 'dddd', 'eeee', 'ffff', 'gggg', 'hhhh', 'iiii', 'jjjj', 'kkkk', 'llll', 'mmmm', 'nnnn', 'ññññ', 'oooo', 'pppp', 'qqqq', 'rrrr', 'ssss', 'tttt', 'uuuu', 'vvvv', 'wwww', 'xxxx', 'yyyy', 'zzzz',
          '1111', '2222', '3333', '5555', '6666', '7777', '8888', '9999',
          '!!!!', '""""', '####', '$$$$', '%%%%', '&&&&', '((((', '))))', '****', '++++', ',,,,', '----', '....', '////', '::::', ';;;;', '<<<<', '====', '>>>>',
          '????', '@@@@', '[[[[', ']]]]', '^^^^', '____', '````', '{{{{', '||||', '}}}}', '~~~~']
        var rg_names = ['redgirasol', 'red girasol']

        if(lower.test(pass)){
          this.criteria[0] = true
        }else{
          this.criteria[0] = false
        }
        if(upper.test(pass)){
          this.criteria[1] = true
        }else{
          this.criteria[1] = false
        }
        if(number.test(pass)){
          this.criteria[2] = true
        }else{
          this.criteria[2] = false
        }
        if(special.test(pass)){
          this.criteria[3] = true
        }else{
          this.criteria[3] = false
        }
        if(pass.length > 7){
          this.criteria[4] = true
        }else{
          this.criteria[4] = false
        }
        var no_consecutive = true
        for(var i = 0; i < consecutive.length; i ++){
          if(pass.toLowerCase().includes(consecutive[i])){
            no_consecutive = false
          }
        }
        this.criteria[5] = no_consecutive

        var no_rg = true
        for(var i = 0; i < rg_names.length; i ++){
          if(pass.toLowerCase().includes(rg_names[i])){
            no_rg = false
          }
        }
        this.criteria[6] = no_rg
      },
      isNumber(e) {
        var evt = (e) ? e : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode >= 48 && charCode <= 57) || charCode === 8 || charCode === 9) {
          return true;
        } else {
          evt.preventDefault();;
        }
      },
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.password {
}

.glyphicon {
  color: #3b3a3e;
}
</style>
